import styled from 'styled-components';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';

export const StyledPocsDropdownMenu = styled(DropdownMenu)`
  width: 16rem;
`;

export const PocsPageWrapper = styled.div`
  display: flex;
`;
