/// <reference types="vite-plugin-svgr/client" />
import styled from 'styled-components';
import RedisLogo from '../../assets/icons/RedisLogo.svg?react';
import RedisLogoMinimized from '../../assets/icons/RedisLogoMinimized.svg?react';

export const RootPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
`;

export const AppBarContainer = styled.div`
  background-color: white;

  & > div > div {
    justify-content: flex-end;
  }
`;

export const PagesWrapper = styled.div`
  padding: 0 4rem;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10rem 5rem;
  font-size: 2rem;
`;

export const FooterLinksContainer = styled.div`
  margin-bottom: 0.2rem;
`;

export const SideBarLogo = styled(RedisLogo)`
  transform: translate(16px, -50%);
  top: 50%;
  position: absolute;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

export const SideBarLogoMinimized = styled(RedisLogoMinimized)`
  transform: translate(0px, -50%) scale(0.52);
  left: 5%;
  position: absolute;
  opacity: 1;
`;
