import styled from 'styled-components';
import { theme } from '@redislabsdev/redis-ui-styles';

import { cardWithShadow } from '../../../../styles/commonAccountDetails.style';

export * from '../ContractPage.style';

export const InfoSection = styled.div`
  height: 43rem;
  ${cardWithShadow}
`;

export const SpanWithGrace = styled.span`
  display: flex;
`;

export const GracePeriodWrapper = styled.span`
  margin-left: 1rem;

  & > span {
    margin-bottom: -2px;
    padding-left: 4px;
    color: ${theme.semantic.color.text.notice500};
  }
`;

export { SMAccountsNewLine } from '../../../../styles/commonAccountDetails.style';
export { SMAccountsTitle } from '../../../../styles/commonAccountDetails.style';
export { SmAccountsSectionStyle } from '../../../../styles/commonAccountDetails.style';
export { TooltipContractWrapper } from '../../../../styles/commonAccountDetails.style';
export { CardExpireDate } from '../../../../styles/commonAccountDetails.style';
export { CardNumber } from '../../../../styles/commonAccountDetails.style';
export { PaymentIcon } from '../../../../styles/commonAccountDetails.style';
export { AccName } from '../../../../styles/commonAccountDetails.style';
export { SpanDisplayFlexInline } from '../../../../styles/commonAccountDetails.style';
export { SeparatorForTitle } from '../../../../styles/commonAccountDetails.style';
export { Separator } from '../../../../styles/commonAccountDetails.style';
export { SectionContent } from '../../../../styles/commonAccountDetails.style';
export { SectionTitle } from '../../../../styles/commonAccountDetails.style';
export { cardWithShadow } from '../../../../styles/commonAccountDetails.style';
