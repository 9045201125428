import styled from 'styled-components';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';
import { theme } from '@redislabsdev/redis-ui-styles';

export const CouponsStatusDropdownMenu = styled(DropdownMenu)`
  width: 16rem;
`;

export const LastModifiedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const CouponCodeTooltipWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;

  > span:first-of-type {
    display: flex;
    overflow: hidden;
    margin-right: 0.5rem;
  }
`;

export const ElipsisTextWithTooltip = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TooltipTitle = styled.div`
  text-align: left;
  margin-bottom: 0.1rem;
  color: ${theme.semantic.color.text.neutral800};
  font-size: 1.4rem;
  font-weight: 600;

  & span {
    font-weight: 400;
  }
`;

export const TooltipContent = styled.p`
  text-align: left;
  color: ${theme.semantic.color.text.neutral800};
  padding-top: 0.4rem;
`;
