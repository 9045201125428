import styled from 'styled-components';
import { DropdownMenu } from '@redislabsdev/redislabs-ui-components';

export const TitleStatusWrapper = styled.div`
  margin: auto 0.3rem auto 5rem;
`;

export const DeleteStatusWrapper = styled.div`
  margin: auto 2rem auto 5rem;
  display: flex;
`;

export const StatusDropdown = styled(DropdownMenu)`
  width: 16rem;
`;
