import styled from 'styled-components';

export const UsageReportPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;

  & > div {
    padding: 1rem 0;
  }
`;

export const UsageReportPopupRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & label {
    margin: 0;
  }
`;

export const UsageReportList = styled.div`
  & > div:first-of-type {
    border-top: 1px solid #eaedf2;
  }
  padding: 0;
`;

export const UsageReportListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaedf2;
  padding: 1rem;

  & > button {
    padding-left: 0;
    padding-right: 0;
    min-width: 3.2rem;
  }
`;
