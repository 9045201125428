import React from 'react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error });
  }

  render(): React.ReactNode {
    if (this.state.error?.message.includes('error loading dynamically imported module')) {
      return (
        <div data-testid="dynamic-import-error">
          An error occurred loading a dynamically imported module. If it is a micro-frontend, make
          sure that it is being served at the expected location.
        </div>
      );
    }
    if (this.state.error) {
      return <div data-testid="unexpected-error">An unexpected error occurred.</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
