const prodValues = {
  accounts: 'accounts',
  contracts: 'contracts',
  bousers: 'bousers',
  subscriptions: 'subscriptions',
  accountsMarketplaces: 'accountsMarketplaces',
  bdbs: 'bdbs',
  poc: 'contracts/poc',
  coupon: 'coupons',
  audit: 'audit/events',
  maintenanceWindow: 'maintenanceWindow',
};

const defaultBaseUrl = {
  production: prodValues,
  test: prodValues,
  development: {
    accounts: process.env.VITE_API_URL_ACCOUNTS || 'http://localhost:8083',
    contracts: process.env.VITE_API_URL_CONTRACTS || 'http://localhost:8081',
    bousers: process.env.VITE_API_URL_BOUSERS || 'http://localhost:8084',
    subscriptions: process.env.VITE_API_URL_SUBSCRIPTIONS || 'http://localhost:8086',
    bdbs: process.env.VITE_API_URL_BDBS || 'http://localhost:8087',
    accountsMarketplaces: process.env.VITE_API_URL_ACCOUNT_MARKETPLACES || 'http://localhost:8088',
    poc: process.env.VITE_API_URL_POC || 'http://localhost:8081/poc', // same as contracts
    coupon: process.env.VITE_API_URL_COUPON || 'http://localhost:8085',
    audit: process.env.VITE_API_URL_AUDIT || 'http://localhost:8082',
  },
};

export default defaultBaseUrl;
