import styled from 'styled-components';
import { theme } from '@redislabsdev/redis-ui-styles';

export const CouponPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;

  & > div {
    padding: 1rem 0;
  }
`;

export const CouponPopupRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  & > label {
    width: 100%;
  }
`;

export const ReadOnlyLabel = styled.div`
  margin-top: 1rem;
  color: ${theme.semantic.color.text.neutral800};
`;

export const DatePickerAligner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  top: 0;
  left: 0;
`;

export const OptionalSpan = styled.span`
  color: ${theme.semantic.color.text.neutral500};
  font-size: 1.2rem;
`;

export const AddableCouponContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;

  input {
    margin-right: 8px !important;
  }

  span {
    margin-right: 4px;
  }
`;

export const CreditExpirationPeriod = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
